
import '@/assets/iconfont.css';
import { getArticleByUrl} from '@/api';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import tocbot from 'tocbot';
import SidebarMobile from '@/layout/SidebarMobile.vue';
import FunctionButton from '@/components/FunctionButton.vue';
import { useStore } from 'vuex';
import SiteFooter from '@/components/SiteFooter.vue';
import {computed} from "vue";
import store from "@/store";
import { v3ImgPreviewFn } from 'v3-img-preview'
export default {
  components: { SidebarMobile, SiteFooter, FunctionButton },
  computed: {
    store() {
      return store
    }
  },
  setup(){
    const router = useRouter();
    const store = useStore();
    store.dispatch("isLoading");
    const article = ref({
      'title': '',
      'content': '',
      'word': 0,
      'readingTime': 0,
      'createdAt': ''
    });
    const articleWrap = ref();
    const articleMenu = ref();

    const handleOpen = () => {
      store.dispatch('openSidebar');
    };
    const isOpen = computed(() =>
        store.state.showSidebar
    );

    onMounted(() => {
      articleWrap;
      articleMenu;
    });

    getArticleByUrl(router.currentRoute.value.params.url).then((res: any) => {
      document.title = res.data.title + ' - Yeliheng的技术小站';
      article.value = res.data;
      articleWrap.value.style.opacity = "1";
      articleMenu.value.style.position = "sticky";
      setTimeout(() => {
        tocbot.init({
          tocSelector: '.article-menu',
          contentSelector: '.markdown-body',
          headingSelector: 'h1, h2, h3',
          hasInnerContainers: true,
          scrollSmoothDuration: 100,
          scrollSmooth: true,
          onClick(e){
            e.preventDefault();
          }
        });
        tocbot.init({
          tocSelector: '.article-menu-mobile',
          contentSelector: '.markdown-body',
          headingSelector: 'h1, h2, h3',
          hasInnerContainers: true,
          scrollSmoothDuration: 100,
          scrollSmooth: true,
          onClick(){
            store.dispatch("closeSidebar");
          }
        });
      if(articleWrap.value.scrollHeight < window.innerHeight){
        articleWrap.value.style.height = window.innerHeight + 'px';
      }
      store.dispatch("notLoading");
      });
    }).then(() => {
      addImgPreview();
    });

    const addImgPreview = () => {
      const imageElements = document.querySelectorAll('.markdown-body img');
      imageElements.forEach((element: any) => {
        element.addEventListener('click', () => {
          v3ImgPreviewFn(element.src);
        })
      })
    }

    const onSiteClick = () => {
      scrollTo(0,0);
    }

    return {
      article,
      articleWrap,
      articleMenu,
      isOpen,
      handleOpen,
      onSiteClick,
    }
  }

}
